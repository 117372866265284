<template>
  <div class="contact-us">
    <nav-bar-white></nav-bar-white>
    <div class="content">
      <div>
        <div>
          <h2 class="animate__animated animate__fadeInUp">
            Texas Business and Immigration Law Firm serving clients worldwide.
          </h2>
          <div class="subtitle mt-5">
            Our commitment to excellence combined with reliability and
            collaboration leads to deliver the best results for our clients.
          </div>
        </div>
      </div>
      <div>
        <div class="address animate__animated animate__fadeInRight">
          <h3>Office Address</h3>
          <div>
            6911 South Highway 6<span class="not_mobile">.</span>
            <br class="mobile" />
            Suite 210<span class="not_mobile">,</span> <br class="mobile" />
            Houston, TX, 77083
          </div>
        </div>
        <div class="contact-numbers animate__animated animate__fadeInRight">
          <h3>Call Us</h3>
          <div><span class="title">Houston: </span>+1 832 866 4580,</div>
          <div><span class="title">Whatsapp: </span>+1 7139697558</div>
          <div><span class="title">India: </span>+91 8547479258</div>
          <div><span class="title">Qatar: </span>+974 66333189</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBarWhite from "../components/NavBarWhite.vue";
export default {
  name: "ContactUs",
  components: {
    NavBarWhite,
  },
};
</script>

<style scoped>
.contact-us {
  height: 100vh;
  width: 100vw;
  box-shadow: inset 0 0 0 2000px rgba(196, 196, 196, 0.2);
  padding: 0 5rem;
}

.content {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 90%;
}

.content > div:first-child {
  background-image: url("../assets/images/contacts-map.png");
  width: 60%;
  height: 100%;
  background-repeat: no-repeat;
  display: grid;
  place-items: center;
}

.content > div:last-child {
  height: 80%;
  margin-top: 15%;
}

h2 {
  font-size: 60px;
  font-weight: bold;
  color: #3d3d3d;
  line-height: 60px;
  font-family: "Playfair Display";
}

h3 {
  color: #2c2c2c;
}

.contact-numbers {
  margin-top: 100px;
}

.address > div,
.contact-numbers > div {
  color: #757575;
  margin-top: 1rem;
}

.title {
  font-weight: bold;
}

.subtitle {
  color: #8e8e8e;
}

.mobile {
  display: none;
}

.not_mobile {
  display: inline;
}

@media only screen and (max-width: 600px) {
  .contact-us {
    padding: 0;
    height: 100%;
  }

  .content {
    flex-wrap: wrap;
  }

  .content > * {
    width: 100%;
  }

  .content > div:first-child {
    width: 100%;
    display: block;
    margin-top: 50px;
    height: auto;
  }

  h2 {
    font-size: 36px;
    line-height: 40px;
    text-align: center;
  }

  .subtitle {
    text-align: center;
  }

  .address,
  .contact-numbers {
    text-align: center;
  }

  h3 {
    font-size: 24px;
  }

  .mobile {
    display: block;
  }

  .not_mobile {
    display: none;
  }
}
</style>