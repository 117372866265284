<template>
  <section>
    <b-table striped hover :items="appointments" :fields="fields"></b-table>
  </section>
</template>

<script>
import { appointmentsRef, getDocs } from "../../config/firebase";
export default {
  created() {
    this.getAppointments();
  },
  data() {
    return {
      appointments: [],
      fields: [
        {
          key: "name",
          sortable: true,
        },
        {
          key: "email",
          sortable: false,
        },
        {
          key: "phone",
          sortable: true,
        },
        {
          key: "service",
          sortable: true,
        },
        {
          key: "message",
          sortable: true,
        },
        {
          key: "created_at",
          sortable: true,
        },
      ],
    };
  },
  methods: {
    async getAppointments() {
      try {
        const snapshot = await getDocs(appointmentsRef);
        this.appointments = snapshot.docs.map((doc) => doc.data());
        this.appointments.forEach((appointment) => {
          appointment.created_at = new Date(appointment.created_at.seconds * 1000);
        });
        console.log(this.appointments);
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style scoped>
</style>