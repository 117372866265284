<template>
  <div class="home">
    <nav-bar></nav-bar>
    <div class="content">
      <h1 class="animate__animated animate__fadeInDown">
        Texas Business and Immigration Law Firm serving clients worldwide.
      </h1>
      <div class="desc">
        Legal expertise you can trust
      </div>
      <b-button variant="primary" @click="goto()" class="animate__animated animate__pulse"
        >Explore Services
        <b-icon icon="arrow-right" aria-hidden="true" class="ml-2"></b-icon
      ></b-button>
    </div>
    <footer-comp class="footer-comp"></footer-comp>
  </div>
</template>

<script>
import FooterComp from "../components/Footer.vue";
import NavBar from "../components/NavBar.vue";
// @ is an alias to /src

export default {
  name: "Home",
  components: { NavBar, FooterComp },
  methods: {
    goto() {
      this.$router.push("/services");
    },
  },
};
</script>

<style scoped>
.home {
  background-image: url("../assets/images/home-bg.png");
  height: 100vh;
  width: 100vw;
  box-shadow: inset 0 0 0 2000px rgba(196, 196, 196, 0.2);
  position: relative;
  padding: 0 5rem;
}

h1 {
  font-size: 60px;
  font-weight: bold;
  color: #fff;
  line-height: 60px;
  font-family: "Playfair Display";
}

.content {
  width: 76%;
  padding: 1rem;
  margin-top: 100px;
}

.content > div {
  font-size: 18px;
  color: #fff;
  margin: 2rem 0;
}
.btn-primary {
  background-color: transparent !important;
  color: #fff !important;
  border: 1px solid var(--primary-color);
  border-radius: 0;
}
.footer-comp {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.desc {
  color: #000 !important;
  /* font-weight: bold; */
}

@media only screen and (max-width: 600px) {
  .home {
    padding: 0;
    background-position: center;
  }
  .content {
    width: 100%;
  }

  h1 {
    font-size: 36px;
    line-height: 50px;
  }

  .content > div {
    font-size: 14px;
  }

  .footer-comp {
    position: relative;
  }
}
</style>