<template>
  <b-modal v-model="show" hide-footer>
    <template #modal-title> Write to us </template>
    <b-form-group label="Name" label-for="name">
      <b-form-input id="name" v-model="name" required></b-form-input>
    </b-form-group>
    <b-form-group label="Email" label-for="email">
      <b-form-input id="email" v-model="email" required></b-form-input>
    </b-form-group>
    <b-form-group label="Phone Number" label-for="phone">
      <b-form-input id="phone" v-model="phone" required></b-form-input>
    </b-form-group>
    <b-form-group label="Message" label-for="message">
      <b-form-textarea
        id="message"
        v-model="message"
        required
      ></b-form-textarea>
    </b-form-group>
    <div style="display: flex; margin-bottom: 20px; align-items: center">
      <input
        type="checkbox"
        style="width: 20px; height: 30px; margin-right: 20px"
        v-model="checkbox"
      />
      <div style="font-size: 18px">
        I agree to
        <a href="/terms-of-use" target="_blank">terms of use</a> and
        <a href="/privacy-policy" target="_blank">privacy policy</a>
      </div>
    </div>
    <b-button
      type="submit"
      variant=""
      @click="submit"
      class="d-block mx-auto"
      style="background-color: #640101"
    >
      Submit
    </b-button>
  </b-modal>
</template>

<script>
import { addDoc, queriesRef } from "../config/firebase";
import { sendWebsiteQueryMail } from "../helpers/mailer";

export default {
  name: "WriteToUsModal",
  props: ["visible"],
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
  data() {
    return {
      name: "",
      email: "",
      phone: "",
      message: "",
      checkbox: false,
    };
  },
  methods: {
    async submit() {
      const data = {
        name: this.name,
        email: this.email,
        phone: this.phone,
        message: this.message,
        created_at: new Date(),
      };

      const field = Object.keys(data).find(
        (key) => data[key] === "" || data[key] === null
      );

      if (field) {
        this.$toast.error(`${field} is required`, {
          position: "top",
        });
        return;
      }

      if (!this.checkbox) {
        this.$toast.error(
          "You must agree to the terms of use and privacy policy.",
          {
            position: "top",
          }
        );
        return;
      }

      try {
        await addDoc(queriesRef, data);
        await sendWebsiteQueryMail(data);
        this.$toast.success("Query Sent. We will get in touch with you.", {
          position: "top",
        });
        this.name = "";
        this.email = "";
        this.phone = "";
        this.message = "";
        this.show = false;
      } catch (error) {
        this.$toast.error("Error while submitting!", {
          position: "top",
        });
      }
    },
  },
};
</script>

<style>
</style>