<template>
  <section>
    <nav-bar-white></nav-bar-white>
    <div class="c12">
      <p class="c13"><span class="c0">&nbsp;PRIVACY POLICY</span></p>
      <p class="c3 c7"><span class="c4"></span></p>
      <p class="c11">
        <span class="c5 c1"
          >This privacy notice discloses the privacy practices of FS Law PC and
          applies to information collected by FS Law PC through its websites,
          blogs, emails, faxes, and phone inquiries and it will notify you of
          the following:
        </span>
      </p>
      <ol class="c8 lst-kix_list_1-0 start" start="1">
        <li class="c6 li-bullet-0">
          <span class="c5 c1"
            >What personally identifiable information is collected from you
            through the website, how it is used and with whom it may be
            shared</span
          >
        </li>
        <li class="c6 li-bullet-0">
          <span class="c5 c1"
            >What choices are available to you regarding the use of your
            data.</span
          >
        </li>
        <li class="c6 li-bullet-0">
          <span class="c5 c1"
            >The security procedures in place to protect the misuse of your
            information.</span
          >
        </li>
        <li class="c3 c10 li-bullet-0">
          <span class="c5 c1"
            >How you can correct any inaccuracies in the information.</span
          >
        </li>
      </ol>
      <p class="c3">
        <span class="c2">Information Collection, Use, and Sharing</span>
      </p>
      <p class="c11">
        <span class="c5 c1"
          >We are the sole owners of the information collected on this site. We
          only have access to/collect personally identifiable information that
          you voluntarily give us via email or other direct contact from you. We
          will not sell or rent this information to anyone.
        </span>
      </p>
      <p class="c3">
        <span class="c1 c5"
          >We will use your information to respond to you, regarding the reason
          you contacted us. We will not share your information with any third
          party outside of our organization, other than as necessary to fulfill
          your request. Unless you ask us not to, we may contact you via email
          or telephone, in the future to tell you about specials, new products
          or services, or changes to this privacy policy.</span
        >
      </p>
      <p class="c3">
        <span class="c2">Your Access to and Control Over Information</span>
      </p>
      <p class="c3">
        <span class="c5 c1"
          >You may opt out of any future contacts from us at any time. You can
          do the following at any time by contacting us via email address or
          phone number given on our website:</span
        >
      </p>
      <ul class="c8 lst-kix_list_2-0 start">
        <li class="c6 li-bullet-0">
          <span class="c5 c1">See what data we have about you, if any.</span>
        </li>
        <li class="c6 li-bullet-0">
          <span class="c5 c1">Change/Correct any data we have about you.</span>
        </li>
        <li class="c6 li-bullet-0">
          <span class="c5 c1">Have us delete any data we have about you.</span>
        </li>
        <li class="c3 c10 li-bullet-0">
          <span class="c5 c1"
            >Express any concern you have about our use of your data</span
          >
        </li>
      </ul>
      <p class="c3"><span class="c2">Security</span></p>
      <p class="c3">
        <span class="c5 c1"
          >We take precautions to protect your information. When you submit
          sensitive information via the website, your information is protected
          both online and offline. Wherever we collect sensitive information
          (such as credit card data), that information is encrypted and
          transmitted to us in a secure way. You can verify this by looking for
          a lock icon in the address bar and looking for &ldquo;https&rdquo; at
          the beginning of the address of the Web page.</span
        >
      </p>
      <p class="c3">
        <span class="c5 c1"
          >While we use encryption to protect sensitive information transmitted
          online, we also protect your information offline. Only Attorneys of
          the firm and authorized employees who need the information to perform
          a specific job are granted access to personally identifiable
          information. The computer/servers in which we store personally
          identifiable information are kept in a secure environment.</span
        >
      </p>
      <p class="c7 c15"><span class="c5 c1"></span></p>
      <p class="c3"><span class="c2">Cookies</span></p>
      <p class="c3">
        <span class="c5 c1"
          >We use &ldquo;cookies&rdquo; on this site. A cookie is a piece of
          data stored on a site visitor&rsquo;s hard drive to help us improve
          your access to our site and identify repeat visitors to our site. For
          instance, when we use a cookie to identify you, you would not have to
          log in a password more than once, thereby saving time while on our
          site. Cookies can also enable us to track and target the interests of
          our users to enhance the experience on our site. Usage of a cookie is
          in no way linked to any personally identifiable information on our
          site.</span
        >
      </p>
      <p class="c3"><span class="c2">Sharing</span></p>
      <p class="c3">
        <span class="c5 c1"
          >We share aggregated demographic information with our partners and
          advertisers. This is not linked to any personal information that can
          identify any individual person.</span
        >
      </p>
      <p class="c3">
        <span class="c5 c1"
          >We use an outside shipping company to ship orders, and a credit card
          processing company to bill users for goods and services. These
          companies do not retain, share store or use personally identifiable
          information for any secondary purposed beyond fulfilling your order or
          service subscription.</span
        >
      </p>
      <p class="c3"><span class="c2">Links</span></p>
      <p class="c3">
        <span class="c5 c1"
          >This website contains links to other sites. Please be aware that we
          are not &nbsp;responsible for the content or privacy practices of such
          other sites. We encourage users to be aware when they leave our site
          and to read the privacy statements of any other site that collects
          personally identifiable information.</span
        >
      </p>
      <p class="c3"><span class="c2">EU Customers and Visitors</span></p>
      <p class="c3">
        <span class="c5 c1"
          >If you are located in the EU, you have the following rights with
          respect to how we &nbsp;processes your personal data:</span
        >
      </p>
      <ol class="c8 lst-kix_list_3-0 start" start="1">
        <li class="c6 li-bullet-0">
          <span class="c5 c1"
            >You have a right to request a copy of the personal data we have
            about you</span
          >
        </li>
        <li class="c6 li-bullet-0">
          <span class="c5 c1"
            >You have a right to request that we correct the information about
            you if you believe it is inaccurate</span
          >
        </li>
        <li class="c3 c10 li-bullet-0">
          <span class="c5 c1"
            >You have a right to request that FS Law PC deletes, erases or
            refrains from using your personal data. We may decline to erase or
            limit the use of your data in certain circumstances such as if we
            have an on-going business relationship with you, we are legally
            required to retain the information. In such a situation we shall
            inform you of the reasons for our decision.</span
          >
        </li>
      </ol>
      <p class="c3"><span class="c2">Contact</span></p>
      <p class="c3">
        <span class="c1">If you as a user of</span><span class="c9">&nbsp;</span
        ><span class="c9"
          ><a
            class="c14"
            href="https://www.google.com/url?q=http://www.fslawpc.com&amp;sa=D&amp;source=editors&amp;ust=1645054863096820&amp;usg=AOvVaw04Ln3B7Rct88d0hi9Y0Pov"
            >www.fslawpc.com</a
          ></span
        ><span class="c1"
          >&nbsp; feel that we are not abiding by this privacy policy, you
          should contact us immediately via email at </span
        ><span class="c2">info@fslawpc.com</span>
      </p>
    </div>
  </section>
</template>

<script>
import NavBarWhite from "../components/NavBarWhite.vue";
export default {
  components: { NavBarWhite },
};
</script>

<style scoped>
@import url("https://themes.googleusercontent.com/fonts/css?kit=fpjTOVmNbO4Lz34iLyptLUXza5VhXqVC6o75Eld_V98");

section {
  padding: 0 5rem;
}

ol.lst-kix_list_3-1 {
  list-style-type: none;
}

ol.lst-kix_list_3-2 {
  list-style-type: none;
}

.lst-kix_list_3-1 > li {
  counter-increment: lst-ctn-kix_list_3-1;
}

ol.lst-kix_list_3-3 {
  list-style-type: none;
}

ol.lst-kix_list_3-4.start {
  counter-reset: lst-ctn-kix_list_3-4 0;
}

ol.lst-kix_list_3-4 {
  list-style-type: none;
}

ol.lst-kix_list_3-0 {
  list-style-type: none;
}

.lst-kix_list_1-1 > li {
  counter-increment: lst-ctn-kix_list_1-1;
}

.lst-kix_list_3-0 > li:before {
  content: "" counter(lst-ctn-kix_list_3-0, decimal) ". ";
}

ol.lst-kix_list_3-1.start {
  counter-reset: lst-ctn-kix_list_3-1 0;
}

ul.lst-kix_list_5-7 {
  list-style-type: none;
}

ul.lst-kix_list_5-8 {
  list-style-type: none;
}

.lst-kix_list_3-1 > li:before {
  content: "" counter(lst-ctn-kix_list_3-1, lower-latin) ". ";
}

.lst-kix_list_3-2 > li:before {
  content: "" counter(lst-ctn-kix_list_3-2, lower-roman) ". ";
}

ul.lst-kix_list_5-5 {
  list-style-type: none;
}

ul.lst-kix_list_5-6 {
  list-style-type: none;
}

ol.lst-kix_list_1-8.start {
  counter-reset: lst-ctn-kix_list_1-8 0;
}

.lst-kix_list_3-5 > li:before {
  content: "" counter(lst-ctn-kix_list_3-5, lower-roman) ". ";
}

ul.lst-kix_list_5-0 {
  list-style-type: none;
}

.lst-kix_list_3-4 > li:before {
  content: "" counter(lst-ctn-kix_list_3-4, lower-latin) ". ";
}

ul.lst-kix_list_5-3 {
  list-style-type: none;
}

ol.lst-kix_list_1-5.start {
  counter-reset: lst-ctn-kix_list_1-5 0;
}

.lst-kix_list_3-3 > li:before {
  content: "" counter(lst-ctn-kix_list_3-3, decimal) ". ";
}

ol.lst-kix_list_3-5 {
  list-style-type: none;
}

ul.lst-kix_list_5-4 {
  list-style-type: none;
}

ol.lst-kix_list_3-6 {
  list-style-type: none;
}

ul.lst-kix_list_5-1 {
  list-style-type: none;
}

ol.lst-kix_list_3-7 {
  list-style-type: none;
}

ul.lst-kix_list_5-2 {
  list-style-type: none;
}

ol.lst-kix_list_3-8 {
  list-style-type: none;
}

.lst-kix_list_3-8 > li:before {
  content: "" counter(lst-ctn-kix_list_3-8, lower-roman) ". ";
}

.lst-kix_list_3-6 > li:before {
  content: "" counter(lst-ctn-kix_list_3-6, decimal) ". ";
}

.lst-kix_list_3-7 > li:before {
  content: "" counter(lst-ctn-kix_list_3-7, lower-latin) ". ";
}

.lst-kix_list_1-2 > li {
  counter-increment: lst-ctn-kix_list_1-2;
}

ol.lst-kix_list_3-7.start {
  counter-reset: lst-ctn-kix_list_3-7 0;
}

.lst-kix_list_3-2 > li {
  counter-increment: lst-ctn-kix_list_3-2;
}

.lst-kix_list_5-0 > li:before {
  content: "\0025cf  ";
}

.lst-kix_list_1-4 > li {
  counter-increment: lst-ctn-kix_list_1-4;
}

ol.lst-kix_list_1-6.start {
  counter-reset: lst-ctn-kix_list_1-6 0;
}

.lst-kix_list_4-8 > li:before {
  content: "\0025aa  ";
}

.lst-kix_list_5-3 > li:before {
  content: "\0025aa  ";
}

.lst-kix_list_4-7 > li:before {
  content: "\0025aa  ";
}

.lst-kix_list_5-2 > li:before {
  content: "\0025aa  ";
}

.lst-kix_list_5-1 > li:before {
  content: "o  ";
}

ul.lst-kix_list_4-8 {
  list-style-type: none;
}

.lst-kix_list_5-7 > li:before {
  content: "\0025aa  ";
}

ul.lst-kix_list_4-6 {
  list-style-type: none;
}

.lst-kix_list_5-6 > li:before {
  content: "\0025aa  ";
}

.lst-kix_list_5-8 > li:before {
  content: "\0025aa  ";
}

ul.lst-kix_list_4-7 {
  list-style-type: none;
}

ul.lst-kix_list_4-0 {
  list-style-type: none;
}

ul.lst-kix_list_4-1 {
  list-style-type: none;
}

ol.lst-kix_list_3-3.start {
  counter-reset: lst-ctn-kix_list_3-3 0;
}

.lst-kix_list_5-4 > li:before {
  content: "\0025aa  ";
}

ul.lst-kix_list_4-4 {
  list-style-type: none;
}

.lst-kix_list_5-5 > li:before {
  content: "\0025aa  ";
}

ul.lst-kix_list_4-5 {
  list-style-type: none;
}

ul.lst-kix_list_4-2 {
  list-style-type: none;
}

ul.lst-kix_list_4-3 {
  list-style-type: none;
}

ol.lst-kix_list_1-0.start {
  counter-reset: lst-ctn-kix_list_1-0 0;
}

.lst-kix_list_6-1 > li:before {
  content: "o  ";
}

.lst-kix_list_6-3 > li:before {
  content: "\0025aa  ";
}

.lst-kix_list_6-0 > li:before {
  content: "\0025cf  ";
}

.lst-kix_list_6-4 > li:before {
  content: "\0025aa  ";
}

.lst-kix_list_3-0 > li {
  counter-increment: lst-ctn-kix_list_3-0;
}

.lst-kix_list_3-3 > li {
  counter-increment: lst-ctn-kix_list_3-3;
}

.lst-kix_list_3-6 > li {
  counter-increment: lst-ctn-kix_list_3-6;
}

.lst-kix_list_6-2 > li:before {
  content: "\0025aa  ";
}

ol.lst-kix_list_3-2.start {
  counter-reset: lst-ctn-kix_list_3-2 0;
}

.lst-kix_list_6-8 > li:before {
  content: "\0025aa  ";
}

.lst-kix_list_6-5 > li:before {
  content: "\0025aa  ";
}

.lst-kix_list_6-7 > li:before {
  content: "\0025aa  ";
}

.lst-kix_list_6-6 > li:before {
  content: "\0025aa  ";
}

ol.lst-kix_list_1-3 {
  list-style-type: none;
}

ol.lst-kix_list_1-4 {
  list-style-type: none;
}

.lst-kix_list_2-6 > li:before {
  content: "\0025cf  ";
}

.lst-kix_list_2-7 > li:before {
  content: "o  ";
}

.lst-kix_list_3-7 > li {
  counter-increment: lst-ctn-kix_list_3-7;
}

ol.lst-kix_list_1-5 {
  list-style-type: none;
}

ol.lst-kix_list_1-6 {
  list-style-type: none;
}

ol.lst-kix_list_1-0 {
  list-style-type: none;
}

.lst-kix_list_2-4 > li:before {
  content: "o  ";
}

.lst-kix_list_2-5 > li:before {
  content: "\0025aa  ";
}

.lst-kix_list_2-8 > li:before {
  content: "\0025aa  ";
}

ol.lst-kix_list_1-1 {
  list-style-type: none;
}

ol.lst-kix_list_1-2 {
  list-style-type: none;
}

ol.lst-kix_list_3-0.start {
  counter-reset: lst-ctn-kix_list_3-0 0;
}

ol.lst-kix_list_1-7 {
  list-style-type: none;
}

.lst-kix_list_1-7 > li {
  counter-increment: lst-ctn-kix_list_1-7;
}

ol.lst-kix_list_1-8 {
  list-style-type: none;
}

ol.lst-kix_list_3-8.start {
  counter-reset: lst-ctn-kix_list_3-8 0;
}

.lst-kix_list_4-0 > li:before {
  content: "\0025cf  ";
}

.lst-kix_list_3-8 > li {
  counter-increment: lst-ctn-kix_list_3-8;
}

.lst-kix_list_4-1 > li:before {
  content: "o  ";
}

ol.lst-kix_list_1-7.start {
  counter-reset: lst-ctn-kix_list_1-7 0;
}

.lst-kix_list_4-4 > li:before {
  content: "\0025aa  ";
}

.lst-kix_list_1-5 > li {
  counter-increment: lst-ctn-kix_list_1-5;
}

.lst-kix_list_4-3 > li:before {
  content: "\0025aa  ";
}

.lst-kix_list_4-5 > li:before {
  content: "\0025aa  ";
}

.lst-kix_list_4-2 > li:before {
  content: "\0025aa  ";
}

.lst-kix_list_4-6 > li:before {
  content: "\0025aa  ";
}

.lst-kix_list_1-8 > li {
  counter-increment: lst-ctn-kix_list_1-8;
}

ol.lst-kix_list_1-4.start {
  counter-reset: lst-ctn-kix_list_1-4 0;
}

.lst-kix_list_3-5 > li {
  counter-increment: lst-ctn-kix_list_3-5;
}

ol.lst-kix_list_1-1.start {
  counter-reset: lst-ctn-kix_list_1-1 0;
}

.lst-kix_list_3-4 > li {
  counter-increment: lst-ctn-kix_list_3-4;
}

ol.lst-kix_list_3-6.start {
  counter-reset: lst-ctn-kix_list_3-6 0;
}

ul.lst-kix_list_6-6 {
  list-style-type: none;
}

ul.lst-kix_list_6-7 {
  list-style-type: none;
}

ul.lst-kix_list_6-4 {
  list-style-type: none;
}

ol.lst-kix_list_1-3.start {
  counter-reset: lst-ctn-kix_list_1-3 0;
}

ul.lst-kix_list_2-8 {
  list-style-type: none;
}

ul.lst-kix_list_6-5 {
  list-style-type: none;
}

ol.lst-kix_list_1-2.start {
  counter-reset: lst-ctn-kix_list_1-2 0;
}

ul.lst-kix_list_6-8 {
  list-style-type: none;
}

ul.lst-kix_list_2-2 {
  list-style-type: none;
}

.lst-kix_list_1-0 > li:before {
  content: "" counter(lst-ctn-kix_list_1-0, decimal) ". ";
}

ul.lst-kix_list_2-3 {
  list-style-type: none;
}

ul.lst-kix_list_2-0 {
  list-style-type: none;
}

ul.lst-kix_list_2-1 {
  list-style-type: none;
}

ul.lst-kix_list_6-2 {
  list-style-type: none;
}

ul.lst-kix_list_2-6 {
  list-style-type: none;
}

ul.lst-kix_list_6-3 {
  list-style-type: none;
}

.lst-kix_list_1-1 > li:before {
  content: "" counter(lst-ctn-kix_list_1-1, lower-latin) ". ";
}

.lst-kix_list_1-2 > li:before {
  content: "" counter(lst-ctn-kix_list_1-2, lower-roman) ". ";
}

ul.lst-kix_list_2-7 {
  list-style-type: none;
}

ul.lst-kix_list_6-0 {
  list-style-type: none;
}

ul.lst-kix_list_2-4 {
  list-style-type: none;
}

ul.lst-kix_list_6-1 {
  list-style-type: none;
}

ul.lst-kix_list_2-5 {
  list-style-type: none;
}

.lst-kix_list_1-3 > li:before {
  content: "" counter(lst-ctn-kix_list_1-3, decimal) ". ";
}

.lst-kix_list_1-4 > li:before {
  content: "" counter(lst-ctn-kix_list_1-4, lower-latin) ". ";
}

ol.lst-kix_list_3-5.start {
  counter-reset: lst-ctn-kix_list_3-5 0;
}

.lst-kix_list_1-0 > li {
  counter-increment: lst-ctn-kix_list_1-0;
}

.lst-kix_list_1-6 > li {
  counter-increment: lst-ctn-kix_list_1-6;
}

.lst-kix_list_1-7 > li:before {
  content: "" counter(lst-ctn-kix_list_1-7, lower-latin) ". ";
}

.lst-kix_list_1-3 > li {
  counter-increment: lst-ctn-kix_list_1-3;
}

.lst-kix_list_1-5 > li:before {
  content: "" counter(lst-ctn-kix_list_1-5, lower-roman) ". ";
}

.lst-kix_list_1-6 > li:before {
  content: "" counter(lst-ctn-kix_list_1-6, decimal) ". ";
}

li.li-bullet-0:before {
  margin-left: -18pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 18pt;
}

.lst-kix_list_2-0 > li:before {
  content: "\0025cf  ";
}

.lst-kix_list_2-1 > li:before {
  content: "o  ";
}

.lst-kix_list_1-8 > li:before {
  content: "" counter(lst-ctn-kix_list_1-8, lower-roman) ". ";
}

.lst-kix_list_2-2 > li:before {
  content: "\0025aa  ";
}

.lst-kix_list_2-3 > li:before {
  content: "\0025cf  ";
}

ol {
  margin: 0;
  padding: 0;
}

table td,
table th {
  padding: 0;
}

.c6 {
  margin-left: 36pt;
  padding-top: 0pt;
  padding-left: 0pt;
  padding-bottom: 0pt;
  line-height: 25px;
  orphans: 2;
  widows: 2;
  text-align: justify;
}

.c4 {
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: "Calibri";
  font-style: normal;
}

.c0 {
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: "Times New Roman";
  font-style: normal;
}

.c2 {
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 12pt;
  font-family: "Times New Roman";
  font-style: normal;
}

.c3 {
  padding-top: 0pt;
  padding-bottom: 8pt;
  line-height: 25px;
  orphans: 2;
  widows: 2;
  text-align: justify;
}

.c15 {
  padding-top: 0pt;
  padding-bottom: 8pt;
  line-height: 25px;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.c11 {
  padding-top: 0pt;
  padding-bottom: 15pt;
  line-height: 25px;
  orphans: 2;
  widows: 2;
  text-align: justify;
}

.c13 {
  padding-top: 0pt;
  padding-bottom: 8pt;
  line-height: 25px;
  orphans: 2;
  widows: 2;
  text-align: center;
}

.c5 {
  color: #000000;
  text-decoration: none;
  vertical-align: baseline;
  font-style: normal;
}

.c1 {
  font-size: 12pt;
  font-family: "Times New Roman";
  font-weight: 400;
}

.c9 {
  font-size: 12pt;
  font-family: "Times New Roman";
  font-weight: 700;
}

.c12 {
  background-color: #ffffff;
  width: 100%;
  padding: 72pt 72pt 72pt 72pt;
}

.c14 {
  color: inherit;
  text-decoration: inherit;
}

.c8 {
  padding: 0;
  margin: 0;
}

.c10 {
  margin-left: 36pt;
  padding-left: 0pt;
}

.c7 {
  height: 11pt;
}

.title {
  padding-top: 24pt;
  color: #000000;
  font-weight: 700;
  font-size: 36pt;
  padding-bottom: 6pt;
  font-family: "Calibri";
  line-height: 25px;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.subtitle {
  padding-top: 18pt;
  color: #666666;
  font-size: 24pt;
  padding-bottom: 4pt;
  font-family: "Georgia";
  line-height: 25px;
  page-break-after: avoid;
  font-style: italic;
  orphans: 2;
  widows: 2;
  text-align: left;
}

li {
  color: #000000;
  font-size: 11pt;
  font-family: "Calibri";
}

p {
  margin: 0;
  color: #000000;
  font-size: 11pt;
  font-family: "Calibri";
}

h1 {
  padding-top: 24pt;
  color: #000000;
  font-weight: 700;
  font-size: 24pt;
  padding-bottom: 6pt;
  font-family: "Calibri";
  line-height: 25px;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

h2 {
  padding-top: 0pt;
  color: #000000;
  font-weight: 700;
  font-size: 18pt;
  padding-bottom: 8pt;
  font-family: "Times New Roman";
  line-height: 25px;
  orphans: 2;
  widows: 2;
  text-align: left;
}

h3 {
  padding-top: 0pt;
  color: #000000;
  font-weight: 700;
  font-size: 13.5pt;
  padding-bottom: 8pt;
  font-family: "Times New Roman";
  line-height: 25px;
  orphans: 2;
  widows: 2;
  text-align: left;
}

h4 {
  padding-top: 0pt;
  color: #000000;
  font-weight: 700;
  font-size: 12pt;
  padding-bottom: 8pt;
  font-family: "Times New Roman";
  line-height: 25px;
  orphans: 2;
  widows: 2;
  text-align: left;
}

h5 {
  padding-top: 11pt;
  color: #000000;
  font-weight: 700;
  font-size: 11pt;
  padding-bottom: 2pt;
  font-family: "Calibri";
  line-height: 25px;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

h6 {
  padding-top: 10pt;
  color: #000000;
  font-weight: 700;
  font-size: 10pt;
  padding-bottom: 2pt;
  font-family: "Calibri";
  line-height: 25px;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
</style>