<template>
  <div>
    <div class="values-row">
      <div
        class="values-col animate__animated animate__fadeInUp"
        v-for="(value, index) in values"
        :key="index"
      >
        <h3 class="values-title">{{ value.title }}</h3>
        <div class="values-content my-4" :style="`color: ${value.color}`">
          {{ value.description }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OurValues",
  data() {
    return {
      values: [
        {
          title: "Excellence",
          description:
            "With a combined experienced of 40 years, our attorneys have shared aspirations, common values and a strong work ethic which contributes to deliver excellent client service across the globe.",
          color: "#FFF",
        },
        {
          title: "Reliability",
          description:
            "We consider the business of our clients as our business and provide a personalized, excellent and reliable service. Our commitment to excellence combined with reliability and collaboration leads to deliver the best results for our clients.",
          color: "#FFF",
        },
        {
          title: "Collaboration",
          description:
            "Collaboration leads us to explore novel strategies to better serve our clients and deliver high quality, cost-effective, personal service. Our firm’s success and strength is based on a culture based on personal relationships, and a shared passion for the best interests of our clients",
          color: "#000",
        },
      ],
    };
  },
};
</script>

<style scoped>
.values-row {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  width: 100%;
}
/* 
.values-content {
  font-size: 14px
} */

.values-col {
  width: 33.33%;
  color: #fff;
  padding: 3rem;
  cursor: pointer;
  height: 360px;
}

.values-col > .values-content {
  line-height: 35px;
}
.values-col:nth-child(1) {
  background-color: #640101;
}

.values-col:nth-child(2) {
  background: rgba(0, 0, 0, 0.81);
}

.values-col:nth-child(3) {
  background: #fff;
  height: 400px;
}

.values-col:nth-child(3) .values-title {
  color: #212121;
}

.values-col:nth-child(3) .values-content {
  color: #a6a6a6;
}

@media only screen and (max-width: 600px) {
  .values-row {
    flex-wrap: wrap;
  }
  .values-col {
    width: 100%;
    height: auto;
    padding: 1rem;
  }

  h3 {
    font-size: 24px;
  }

  .values-content {
    font-size: 16px;
  }
}
</style>