<template>
  <section>
    <div class="footer-row">
      <!-- <div class="footer-col"></div> -->
      <div class="footer-col animate__animated animate__fadeInUp" @click="goto('/services')">
        <h3 class="footer-title">Services</h3>
        <div class="footer-content">Explore all our expertise</div>
        <b-icon icon="arrow-right" variant="primary" class="mt-4"></b-icon>
      </div>
      <div class="footer-col animate__animated animate__fadeInUp" @click="goto('/about')">
        <h3 class="footer-title">About Us</h3>
        <div class="footer-content">Learn more about our team</div>
        <b-icon icon="arrow-right" variant="primary" class="mt-4"></b-icon>
      </div>
      <div class="footer-col animate__animated animate__fadeInUp" @click="goto('/contact')">
        <h3 class="footer-title">Contact Us</h3>
        <!-- <div class="footer-content">Reach out to us</div> -->
        <b-icon icon="arrow-right" variant="primary" class="mt-4"></b-icon>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Footer",
  methods: {
    goto(path) {
      this.$router.push(path);
    },
  },
};
</script>

<style scoped>
.footer-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-col {
  width: 33.33%;
  height: 200px;
  color: #fff;
  padding: 3rem;
  cursor: pointer;
}
.footer-col:nth-child(1) {
  background-color: #640101;
}

.footer-col:nth-child(2) {
  background-color: #640101;
  background-image: url("../assets/images/footer-item-1.png");
  box-shadow: inset 0 0 0 2000px rgba(49, 49, 49, 0.2);
}

.footer-col:nth-child(3) {
  background-color: #fff;
  color: #000;
}

.text-primary {
  color: #fff !important;
}

@media only screen and (max-width: 600px) {
  .footer-row {
    flex-wrap: wrap;
  }
  .footer-col {
    width: 100%;
  }
}
</style>