import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import PracticeAreas from "../views/PracticeAreas.vue";
import ContactUs from "../views/ContactUs.vue";
import AboutUs from "../views/AboutUs.vue";
import NewsAndBlogs from "../views/NewsAndBlogs.vue";
import ReadNews from "../views/ReadNews.vue";
import LawType from "../views/LawType.vue";
import Appointment from "../views/Appointment.vue";
import PayBill from "../views/PayBill.vue";
import TermsOfUse from "../views/TermsOfUse.vue";
import PrivacyPolicy from "../views/PrivacyPolicy.vue";
import Login from "../views/Admin/Login.vue";
import AdminDashboard from "../views/Admin/Dashboard.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/services",
    name: "PracticeAreas",
    component: PracticeAreas,
  },
  {
    path: "/contact",
    name: "ContactUs",
    component: ContactUs,
  },
  {
    path: "/about",
    name: "AboutUs",
    component: AboutUs,
  },
  {
    path: "/news-and-blogs",
    name: "NewsAndBlogs",
    component: NewsAndBlogs,
  },
  {
    path: "/read-news",
    name: "ReadNews",
    component: ReadNews,
  },
  {
    path: "/services/:type",
    name: "LawType",
    component: LawType,
  },
  {
    path: "/appointment",
    name: "Appointment",
    component: Appointment,
  },
  {
    path: "/pay-bill",
    name: "PayBill",
    component: PayBill,
  },
  {
    path: "/terms-of-use",
    name: "TermsOfUse",
    component: TermsOfUse,
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/admin/dashboard",
    name: "AdminDashboard",
    component: AdminDashboard,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
