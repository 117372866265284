<template>
  <div class="contact-us">
    <nav-bar-white></nav-bar-white>
    <div class="content">
      <div>
        <h2 class="heading" style="color: #000; font-size: 24px">
          Book an appointment
        </h2>
        <form>
          <label for="name" style="font-size: 16px; height: 20px">Name</label>
          <input type="text" id="name" v-model="form.name" required />
          <label for="email" style="font-size: 16px; height: 20px">Email</label>
          <input type="email" id="email" v-model="form.email" required />
          <label for="phone" style="font-size: 16px; height: 20px"
            >Contact Number</label
          >
          <input type="text" id="phone" v-model="form.phone" required />
          <label for="service" style="font-size: 16px; height: 20px"
            >Select the service</label
          >
          <select id="service" v-model="form.service" required>
            <option v-for="service in services" :key="service" :value="service">
              {{ service }}
            </option>
          </select>
          <label for="message" style="font-size: 16px; height: 20px"
            >Message</label
          >
          <textarea
            id="message"
            rows="5"
            v-model="form.message"
            required
          ></textarea>
          <div style="display: flex; margin-bottom: 20px; align-items: center">
            <input
              type="checkbox"
              style="width: 30px; margin-right: 20px"
              v-model="checkbox"
            />
            <div style="font-size: 18px">
              I agree to
              <a href="/terms-of-use" target="_blank">terms of use</a> and
              <a href="/privacy-policy" target="_blank">privacy policy</a>
            </div>
          </div>
          <b-button variant="" @click="submit">Book an appointment</b-button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { addDoc, appointmentsRef } from "../config/firebase";
import { sendNewAppointmentMail } from "../helpers/mailer";

import NavBarWhite from "../components/NavBarWhite.vue";
export default {
  name: "ContactUs",
  components: {
    NavBarWhite,
  },
  data() {
    return {
      checkbox: false,
      form: {
        name: "",
        email: "",
        phone: "",
        service: "Select service",
        message: "",
      },
      services: ["Select service", "Immigration service", "Business service"],
    };
  },
  mounted() {
    const script = document.createElement("script");
    script.src =
      "https://www.paypal.com/sdk/js?client-id=ARau1KoC3xfEdTOusbz30J_F_GKX5gDTVSVii1pVzAYGlr3527dCVWDPIR9GTYMoRyuERnmxQqisZftq";
    script.addEventListener("load", this.setLoaded);
    document.body.appendChild(script);
  },
  methods: {
    async submit() {
      try {
        const field = Object.keys(this.form).find((key) => {
          return this.form[key] === "";
        });

        if (field) {
          this.$toast.error(`${field} is required.`, {
            position: "top",
          });
          return;
        }

        if (!this.checkbox) {
          this.$toast.error(
            "You must agree to the terms of use and privacy policy.",
            {
              position: "top",
            }
          );
          return;
        }

        const data = {
          ...this.form,
          created_at: new Date(),
        };
        await addDoc(appointmentsRef, data);
        await sendNewAppointmentMail(data);
        this.$toast.success(
          "Appointment request sent. We will get back to you soon.",
          {
            position: "top",
          }
        );
        this.form = {
          name: "",
          email: "",
          phone: "",
          service: "Select service",
          message: "",
        };
        this.checkbox = false;
      } catch (error) {
        this.$toast.error(error.message, {
          position: "top",
        });
      }
    },
  },
};
</script>

<style scoped>
.contact-us {
  height: 100vh;
  width: 100vw;
  box-shadow: inset 0 0 0 2000px rgba(196, 196, 196, 0.2);
  padding: 0 5rem;
}

.content {
  background-image: url("../assets/images/contacts-map.png");
}

.content > div {
  width: 70%;
  height: 100%;
  background-repeat: no-repeat;
  display: grid;
  place-items: center;
  margin: 5% auto;
}

form {
  width: 100%;
}

label {
  color: #000;
  display: block;
  font-size: 14px;
  margin-top: 10px;
}

input,
select,
textarea {
  width: 100%;
  border: 1px solid #640101;
  border-radius: 5px;
  outline: none;
}

input,
select {
  height: 40px;
}

button {
  width: 200px;
  height: 50px;
  background-color: #640101;
  color: #fff;
  border: none;
  outline: none;
  margin: 50px auto 0 auto;
  font-size: 16px;
  display: block;
}

.btn:hover {
  background-color: #fff;
  color: #640101;
  outline: 1px solid #640101;
}

@media only screen and (max-width: 600px) {
  section {
    padding: 0 2rem;
  }
  .contact-us {
    padding: 0;
    height: 100%;
  }

  .content {
    flex-wrap: wrap;
  }

  .content > div {
    width: 90%;
    display: block;
    margin-top: 50px;
    height: auto;
  }

  h2 {
    font-size: 36px;
    line-height: 40px;
    text-align: center;
  }
}
</style>