<template>
  <div>
    <b-navbar toggleable="lg">
      <b-navbar-brand to="/">
        <img src="../assets/images/fslawpc-logo-3.svg" alt="FS Law PC" />
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="mx-auto">
          <b-nav-item to="/">Home</b-nav-item>
          <b-nav-item to="/services">Services</b-nav-item>
          <b-nav-item to="/about">About</b-nav-item>
          <!-- <b-nav-item to="/news-and-blogs">News and Blogs</b-nav-item> -->
          <b-nav-item to="/contact">Contact Us</b-nav-item>
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-nav-item @click="writeToUsDialog = true">
            <b-button variant="primary" class="animate__animated animate__tada"
              >Send a message</b-button
            >
          </b-nav-item>
          <b-nav-item to="/appointment">
            <b-button variant="primary" class="animate__animated animate__tada"
              >Consultation</b-button
            >
          </b-nav-item>
          <b-nav-item to="/pay-bill">
            <b-button variant="primary" class="animate__animated animate__tada"
              >Make a payment</b-button
            >
          </b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <write-to-us-modal
      :visible="writeToUsDialog"
      @close="writeToUsDialog = false"
    ></write-to-us-modal>
  </div>
</template>

<script>
import WriteToUsModal from "./WriteToUsModal.vue";
export default {
  components: { WriteToUsModal },
  name: "NavBar",
  data() {
    return {
      writeToUsDialog: false,
    };
  },
};
</script>

<style scoped>
.nav-item {
  margin-right: 1rem;
}

>>> .nav-link:hover {
  color: #640101 !important;
}

.nav-link {
  color: #fff !important;
}

.btn-primary {
  background-color: transparent !important;
  color: #3e3e3e !important;
  border: 1px solid var(--primary-color);
  /* border-radius: 0; */
}

.btn:hover {
  background-color: var(--primary-color) !important;
  color: #fff !important;
}

@media only screen and (max-width: 600px) {
  .navbar-collapse {
    text-align: center;
  }
}
</style>