// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
  getFirestore,
  collection,
  addDoc,
  setDoc,
  doc,
  getDocs,
} from "firebase/firestore";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAuIsJIsskaEJZtpZxEqHH5IFI2RrcV3TE",
  authDomain: "fs-law-pc.firebaseapp.com",
  projectId: "fs-law-pc",
  storageBucket: "fs-law-pc.appspot.com",
  messagingSenderId: "79754802969",
  appId: "1:79754802969:web:64400566bd19564ffef788",
  measurementId: "G-5SEVN055PW",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

//collections
const auth = getAuth(app);
const db = getFirestore(app);
const queriesRef = collection(db, "queries");
const appointmentsRef = collection(db, "appointments");
const usersRef = collection(db, "users");

export {
  auth,
  analytics,
  queriesRef,
  appointmentsRef,
  doc,
  addDoc,
  setDoc,
  signInWithPopup,
  GoogleAuthProvider,
  usersRef,
  getDocs,
};
