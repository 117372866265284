<template>
  <section>
    <b-table striped hover :items="queries" :fields="fields"></b-table>
  </section>
</template>

<script>
import { getDocs, queriesRef } from "../../config/firebase";
export default {
  created() {
    this.getAppointments();
  },
  data() {
    return {
      queries: [],
      fields: [
        {
          key: "name",
          sortable: true,
        },
        {
          key: "email",
          sortable: false,
        },
        {
          key: "phone",
          sortable: true,
        },
        {
          key: "message",
          sortable: true,
        },
        {
          key: "created_at",
          sortable: true,
        },
      ],
    };
  },
  methods: {
    async getAppointments() {
      try {
        const snapshot = await getDocs(queriesRef);
        this.queries = snapshot.docs.map((doc) => doc.data());
        this.queries.forEach((queries) => {
          queries.created_at = new Date(queries.created_at.seconds * 1000);
        });
        console.log(this.queries);
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style scoped>
</style>