<template>
  <section>
    <div
      class="attorney animate__animated animate__fadeInUp"
      v-for="(attorney, index) in attorneys"
      :key="index"
    >
      <div class="attorney-col">
        <img
          :src="require(`../assets/images/${attorney.image}`)"
          :alt="attorney.name"
          class="attorney-img"
        />
        <div class="attorney-details">
          <h5>{{ attorney.name }}</h5>
          <p>{{ attorney.title }}</p>
        </div>
      </div>
      <div class="attorney-col">
        <div class="attorney-info">
          <h4 class="mb-4">Meet {{ attorney.name.split(" ")[0] }}...</h4>
          <p v-html="attorney.description" class="description"></p>
          <b-button
            v-b-toggle="`collapse-${index}`"
            style="background-color: #640101; outline: none; border: none"
            class="d-block ml-auto"
          >
            Read More
            <b-icon icon="chevron-down" class="ml-2"></b-icon>
          </b-button>
          <b-collapse :id="`collapse-${index}`" class="collapse mt-2">
            <b-card>
              <div class="card-body text-center">
                <b-row>
                  <b-col lg="4" sm="12" md="12">
                    <img src="../assets/images/education.png" alt="Education" />
                    <h6 class="mb-4">Education</h6>
                    <ul>
                      <li
                        v-for="(education, ei) in attorney.education"
                        :key="ei"
                      >
                        {{ education }}
                      </li>
                    </ul>
                  </b-col>
                  <b-col lg="4" sm="12" md="12">
                    <img
                      src="../assets/images/admission.png"
                      alt="Bar Admission"
                    />
                    <h6 class="mb-4">Bar Admissions</h6>
                    <ul>
                      <li v-for="(bar, bi) in attorney.barAdmissions" :key="bi">
                        {{ bar }}
                      </li>
                    </ul>
                  </b-col>
                  <b-col lg="4" sm="12" md="12">
                    <img
                      src="../assets/images/approval.png"
                      alt="Professional Affiliations"
                    />
                    <h6 class="mb-4 mt-3">Professional Affiliations</h6>
                    <ul>
                      <li
                        v-for="(
                          affiliation, ai
                        ) in attorney.professionalAffiliations"
                        :key="ai"
                      >
                        {{ affiliation }}
                      </li>
                    </ul>
                  </b-col>
                </b-row>
              </div>
            </b-card>
          </b-collapse>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Attorneys",
  data() {
    return {
      attorneys: [
        {
          name: "Busayo Fasidi",
          title: "Attorney at Law",
          description:
            "Olubusayo ‘Busayo’ Fasidi is an entrepreneur and attorney with over 18 years of legal experience. She is a partner at FS Law PC. She is licensed to practice law in the States of Texas and New York and in Nigeria.  Her experience includes counseling and representing corporations in general corporate and commercial matters, corporate and family immigration, and employment law. <br /><br />As an entrepreneur, Busayo understands the intricacies of growing a start-up company. She provides support to growing business by serving as a legal and corporate consultant. She has worked as outside general counsel for various businesses including an engineering firm in Houston. <br /><br />Busayo also has experience representing individuals and families in family-based immigrant and nonimmigrant US visa applications, asylum, and removal proceedings. She currently serves as an Independent Hearing Examiner with the Texas Education Agency, where she presides over disputes relating to certain Teacher Employment Contracts. <br /> <br />An ardent advocate against abuse, Olubusayo is the host of the podcast ‘Broken Road by Busayo’ where she proclaims healing, liberation, and freedom from abuse. She has a Master of Arts in Clinical Mental Health Counseling from the Houston Baptist University.",
          image: "busayo.jpg",
          education: [
            "University of Houston Law Center: LLM",
            "University of Ibadan: LLB",
            "Nigerian Law School: BL",
            "Houston Baptist University: MA Clinical Mental Health Counseling",
          ],
          barAdmissions: [
            "State Bar of Texas",
            "State Bar of New York",
            "U.S District Court Southern District of Texas",
            "U.S. District Court Northern District of Texas",
            "Supreme Court of Nigeria",
          ],
          professionalAffiliations: [
            "Member, American Immigration Law Association",
            "Member, College of the State Bar of Texas",
            "Member, American Bar Association",
            "Member, American Counseling Association",
          ],
        },
        {
          name: "Jeeva Sugathan",
          title: "Attorney at Law",
          description:
            "Jeeva Sugathan is a Partner at FS Law PC and has over 22 years of legal experience. She is licensed to practice law in the State of Texas and India. As a practitioner, Jeeva has extensive experience in  a  broad range of corporate and commercial transactions as well as employment and investor based immigration.<br /><br />Jeeva advises on all facets of corporate law and employment based and investor visas for her growing base of multinational clients. She advises clients from setting up their business and providing general counsel services including corporate governance, compliance and dispute resolution. Jeeva is a trained mediator and strives to help her clients through alternative dispute resolution methods.<br /><br />Her immigration practice includes advising on employment-based visas, such as EB-1,EB-2/EB-3 labor certification, extraordinary abilities and outstanding professors and researchers; L-1A executive transfers; investor visas, such as EB-5 and E-2; visas for professional athletes, artists, and performers; and visas for health care professionals.<br /><br />Prior to entering private practice, Jeeva worked  for several prominent companies and law firms , including an American multinational oil and gas corporation, where she focused on oil and gas law and a power transmission company where she handled corporate counsel duties and litigation management. Her experience also include working for immigration law firms in Houston Texas.<br /><br/>She has the knowledge and experience to give practical and reliable legal advice for the challenges faced by her clients.",
          image: "jeeva.jpg",
          education: [
            "University of Houston Law Center : LLM",
            "University of Kerala India: LLB, M.B.L, LL.M",
          ],
          barAdmissions: [
            "State Bar of Texas",
            "U.S. District Court Southern District of Texas",
            "Bar Council of India",
          ],
          professionalAffiliations: [
            "Member, American Immigration Lawyers Association",
            "Member, American Bar Association",
            "Member, Houston Bar Association",
          ],
        },
      ],
    };
  },
};
</script>

<style scoped>
.attorney {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.attorney-col {
  position: relative;
  margin-bottom: 40px;
}

.attorney-col:nth-child(2) {
  background-color: #fff;
  padding: 0 3rem;
  /* position: absolute; */
  width: 76%;
  /* height: 350px; */
  /* right: 0; */
  /* display: block; */
  /* margin-top: 50px; */
}

.attorney-img {
  width: 350px;
}

.attorney-details {
  /* position: absolute; */
  /* bottom: 0; */
  /* color: #fff; */
  /* left: 30px; */
  margin-top: 1rem;
}

h4 {
  color: #4b4b4b;
}

h6 {
  /* text-align: center; */
}

.row {
  /* margin-top: 1rem; */
}

.description,
li {
  color: #828282;
  font-size: 16px;
  text-align: left;
}

@media only screen and (max-width: 600px) {
  .attorney {
    flex-wrap: wrap;
    margin-bottom: 20px;
  }

  .attorney-col {
    width: 100%;
    position: relative;
    padding: 0;
    margin-top: 20px;
  }

  .attorney-col:nth-child(2) {
    position: relative;
    width: 100%;
    padding: 0;
    margin-top: 20px;
  }
}
</style>