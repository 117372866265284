<template>
  <section>
    <div class="info">
      <span class="date">{{ item.date }}</span>
      <span class="tag">{{ item.tag }}</span>
    </div>
    <h2 class="mt-2">{{ item.title }}</h2>
    <div class="descriptions">
      <p>{{ item.descriptions[0] }}</p>
      <div class="news-content">
        <div>
          <p>{{ item.descriptions[1] }}</p>
          <p class="mt-2">{{ item.descriptions[2] }}</p>
          <p class="mt-4">{{ item.descriptions[3] }}</p>
        </div>
        <img src="../../assets/images/read-news-img.png" alt="" />
      </div>
      <p class="mt-4">{{ item.descriptions[4] }}</p>
      <p class="mt-4">{{ item.descriptions[5] }}</p>
      <p class="mt-4">{{ item.descriptions[6] }}</p>
    </div>
  </section>
</template>

<script>
export default {
  name: "News",
  data() {
    return {
      item: {
        date: "22 Jul 2021",
        tag: "Inclusion",
        title: "Saving the Afghan Interpreters: Our Moral Duty",
        descriptions: [
          "Despite assurances from the Biden administration, the evacuation of Afghan interpreters is tied up in bureaucratic squabbling",
          "Busayo Fasidi, started her private law practice in November 2008. Prior to that she was opportune to serve as a student attorney at the University of Houston Immigration Law Clinic, where she was mentored by some of the best in the field of U.S. Immigration Law.",
          "She is admitted to practice law in the States of New York and Texas. She is also admitted to the U.S District Courts of the Northern and Southern Districts of Texas.",
          "She holds a Masters in International Law from the University of Houston Law Center (2008) and a Bachelors in Law (LLB/2002) from the University of Ibadan, Nigeria. Ms. Fasidi is also admitted to practice law in Nigeria.",
          "Busayo Fasidi, started her private law practice in November 2008. Prior to that she was opportune to serve as a student attorney at the University of Houston Immigration Law Clinic, where she was mentored by some of the best in the field of U.S. Immigration Law.",
          "She is admitted to practice law in the States of New York and Texas. She is also admitted to the U.S District Courts of the Northern and Southern Districts of Texas.",
          "She holds a Masters in International Law from the University of Houston Law Center (2008) and a Bachelors in Law (LLB/2002) from the University of Ibadan, Nigeria. Ms. Fasidi is also admitted to practice law in Nigeria.",
        ],
      },
    };
  },
};
</script>

<style scoped>
.info {
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  width: 20%;
}

.date {
  font-size: 12px;
}

.tag {
  font-size: 12px;
  color: #640101;
}

h2 {
  color: #292929;
}

.descriptions {
  color: #757575;
  font-size: 18px;
}

.news-content {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.news-content > div {
  width: 60%;
}

img {
  width: 280px;
}

@media only screen and (max-width: 600px) {
  .info {
    width: 100%;
  }

  h2 {
    font-size: 20px;
  }

  p {
    font-size: 16px;
  }

  .news-content {
    flex-direction: column;
  }
  .news-content > div {
    width: 100%;
  }
  img {
    width: 100%;
  }
}
</style>