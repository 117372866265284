<template>
  <section>
    <nav-bar-white></nav-bar-white>
    <div class="content">
      <div>
        <h2 class="heading" style="color: #000; font-size: 24px">
          Pay the Bill
        </h2>
        <form>
          <label for="invoice_number" style="font-size: 16px; height: 20px"
            >Invoice Number</label
          >
          <input type="text" id="name" v-model="form.invoice_number" />
          <label for="email" style="font-size: 16px; height: 20px"
            >Amount</label
          >
          <input type="email" id="email" v-model="form.amount" />
          <div
            style="display: flex; margin-bottom: 20px; align-items: center"
            class="mt-5"
          >
            <input type="checkbox" style="width: 30px; margin-right: 20px" />
            <div style="font-size: 18px">
              I agree to
              <a href="/terms-of-use" target="_blank">terms of use</a> and
              <a href="/privacy-policy" target="_blank">privacy policy</a>
            </div>
          </div>
          <div ref="paypal" id="paypal-button-container"></div>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import NavBarWhite from "../components/NavBarWhite.vue";
export default {
  components: { NavBarWhite },
  data() {
    return {
      form: {
        invoice_number: "",
        amount: "",
      },
    };
  },
  mounted() {
    const script = document.createElement("script");
    script.src =
      "https://www.paypal.com/sdk/js?client-id=ARau1KoC3xfEdTOusbz30J_F_GKX5gDTVSVii1pVzAYGlr3527dCVWDPIR9GTYMoRyuERnmxQqisZftq";
    script.addEventListener("load", this.setLoaded);
    document.body.appendChild(script);
  },
  methods: {
    setLoaded() {
      window.paypal
        .Buttons({
          style: {
            shape: "rect",
            color: "blue",
            layout: "vertical",
            label: "paypal",
          },

          createOrder: function (data, actions) {
            return actions.order.create({
              purchase_units: [{ amount: { currency_code: "USD", value: 1 } }],
            });
          },

          onApprove: function (data, actions) {
            return actions.order.capture().then(function (orderData) {
              // Full available details
              console.log(
                "Capture result",
                orderData,
                JSON.stringify(orderData, null, 2)
              );

              // Show a success message within this page, e.g.
              const element = document.getElementById(
                "paypal-button-container"
              );
              element.innerHTML = "";
              element.innerHTML = "<h3>Thank you for your payment!</h3>";

              // Or go to another URL:  actions.redirect('thank_you.html');
            });
          },

          onError: function (err) {
            console.log(err);
          },
        })
        .render("#paypal-button-container");
    },
  },
};
</script>

<style scoped>
section {
  padding: 0 5rem;
  height: 100vh;
}

.content {
  background-image: url("../assets/images/contacts-map.png");
  height: 100%;
}

.content > div {
  width: 70%;
  background-repeat: no-repeat;
  display: grid;
  place-items: center;
  margin: 5% auto;
}

form {
  width: 100%;
}

label {
  color: #000;
  display: block;
  font-size: 14px;
  margin-top: 10px;
}

input,
select,
textarea {
  width: 100%;
  border: 1px solid #640101;
  border-radius: 5px;
  outline: none;
}

input,
select {
  height: 40px;
}

button {
  width: 200px;
  height: 50px;
  background-color: #640101;
  color: #fff;
  border: none;
  outline: none;
  margin: 50px auto 0 auto;
  font-size: 16px;
  display: block;
}

.btn:hover {
  background-color: #fff;
  color: #640101;
  outline: 1px solid #640101;
}

@media only screen and (max-width: 600px) {
  section {
    padding: 0 2rem;
  }
  .contact-us {
    padding: 0;
    height: 100%;
  }

  .content {
    flex-wrap: wrap;
  }

  .content > * {
    width: 100%;
  }

  .content > div:first-child {
    width: 100%;
    display: block;
    margin-top: 50px;
    height: auto;
  }

  h2 {
    font-size: 36px;
    line-height: 40px;
    text-align: center;
  }
}
</style>