<template>
  <div>
    <nav-bar-white class="nav-bar"></nav-bar-white>
    <news-header :newsHeader="newsHeader"></news-header>
    <div class="content">
      <news></news>
      <also-read class="mt-5"></also-read>
    </div>
    <footer-alt class="item"></footer-alt>
  </div>
</template>

<script>
import FooterAlt from "../components/FooterAlt.vue";
import NavBarWhite from "../components/NavBarWhite.vue";
import AlsoRead from "../components/News/AlsoRead.vue";
import NewsHeader from "../components/News/Header.vue";
import News from "../components/News/News.vue";
export default {
  components: { NewsHeader, NavBarWhite, FooterAlt, AlsoRead, News },
  name: "ReadNews",
  data() {
    return {
      news: [
        {
          cover_image: "news_cover.png",
          title: "Saving the Afghan Interpreters: Our Moral Duty",
          description:
            "Despite assurances from the Biden administration, the evacuation of Afghan interpreters is tied up in bureaucratic squabbling",
          date: "22 Jul 2021",
          tag: "Inclusion",
        },
        {
          cover_image: "news_cover.png",
          title: "Saving the Afghan Interpreters: Our Moral Duty",
          description:
            "Despite assurances from the Biden administration, the evacuation of Afghan interpreters is tied up in bureaucratic squabbling",
          date: "22 Jul 2021",
          tag: "Inclusion",
        },
        {
          cover_image: "news_cover.png",
          title: "Saving the Afghan Interpreters: Our Moral Duty",
          description:
            "Despite assurances from the Biden administration, the evacuation of Afghan interpreters is tied up in bureaucratic squabbling",
          date: "22 Jul 2021",
          tag: "Inclusion",
        },
      ],
      newsHeader: "read-news-header.png",
    };
  },
};
</script>

<style scoped>
.nav-bar,
.content {
  padding: 0 5rem;
}

.content {
  margin-top: 100px;
}

.item {
  padding-left: 5rem;
}

@media only screen and (max-width: 600px) {
  .nav-bar {
    padding: 0rem;
  }

  .content {
    padding: 1rem;
    margin-top: 20px;
  }

  .item {
    padding-left: 1rem;
  }
}
</style>