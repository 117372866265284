import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'

import './app.scss'
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
import App from './App.vue'
import router from './router'
import store from './store'

import './config/firebase'
import './plugins/vue-toast-notification'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
