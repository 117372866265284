<template>
  <div>
    <nav-bar-white></nav-bar-white>
    <div class="header">
      <img
        :src="require(`../assets/images/${item.bannerImage}`)"
        alt="News Header"
        width="100%"
      />
      <h1>{{ item.title }}</h1>
    </div>
    <div class="content">
      <div class="about mt-5">
        <h2>About the service</h2>
        <p>
          {{ item.about }}
        </p>
      </div>
      <!-- <div class="promise mt-5">
        <div>
          <h2>Our Promise</h2>
          <img
            :src="require(`../assets/images/${item.promiseImage}`)"
            alt="Promise"
            class="promise-img mbl mb-4 mx-auto"
          />
          <p v-html="item.promise"></p>
        </div>
        <img
          :src="require(`../assets/images/${item.promiseImage}`)"
          alt="Promise"
          class="promise-img not_mbl"
        />
      </div> -->
      <!-- <div class="stats">
        <div class="stat" v-for="(stat, index) in item.stats" :key="index">
          <h2>{{ stat.title }}</h2>
          <p>{{ stat.value }}</p>
        </div>
      </div> -->
      <div class="services mt-5">
        <h2>Services Provided</h2>
        <!-- <p v-for="(service, index) in item.services" :key="index">
          {{ service }}
        </p> -->
        <div class="accordion mr-4" role="tablist">
          <ol>
            <li v-for="(service, index) in item.services" :key="index">
              <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1 text-left">
                  <b-button
                    block
                    v-b-toggle="`collapse-${index}`"
                    variant="light"
                    >{{ service.title }}
                    <b-icon icon="chevron-down" class="float-right"></b-icon>
                  </b-button>
                </b-card-header>
                <b-collapse :id="`collapse-${index}`" class="collapse">
                  <b-card-body>
                    <b-card-text>
                      <ol>
                        <li
                          v-for="(type, tIndex) in service.types"
                          :key="tIndex"
                        >
                          <div>
                            <b v-if="type.description">
                              <u>{{ type.title }}: </u></b
                            >
                            <span v-else> {{ type.title }}</span>
                            <p>{{ type.description }}</p>
                          </div>
                        </li>
                      </ol>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </li>
          </ol>
        </div>
      </div>
    </div>
    <footer-alt class="item"></footer-alt>
  </div>
</template>

<script>
import FooterAlt from "../components/FooterAlt.vue";
import NavBarWhite from "../components/NavBarWhite.vue";
export default {
  components: { NavBarWhite, FooterAlt },
  computed: {
    item() {
      return this.services.find(
        (item) => item.type === this.$route.params.type
      );
    },
  },
  name: "LawType",
  data() {
    return {
      services: [
        {
          type: "imigration",
          title: "Immigration Law",
          about:
            "We provide comprehensive business immigration services to individuals and corporations from all over the world",
          bannerImage: "immigration-law-banner.jpg",
          promise:
            "Busayo Fasidi, started her private law practice in November 2008. Prior to that she was opportune to serve as a student attorney at the University of Houston Immigration Law Clinic, where she was mentored by some of the best in the field of U.S. Immigration Law.<br /><br /> She is admitted to practice law in the States of New York and Texas. She is also admitted to the U.S District Courts of the Northern and Southern Districts of Texas.",
          promiseImage: "imigration-promise.png",
          stats: [
            {
              title: "250+",
              value: "Clients",
            },
            {
              title: "250+",
              value: "Clients",
            },
            {
              title: "250+",
              value: "Clients",
            },
            {
              title: "250+",
              value: "Clients",
            },
          ],
          // services: [
          //   "Visitor Visas",
          //   "Work Visas",
          //   "Other Work Visas",
          //   "Permanent Resident /Green Card",
          //   "Diversity Visa/Lottery",
          // ],
          services: [
            {
              title: "Visitor Visas",
              types: [
                {
                  title: "B-1 Visa",
                  description:
                    "This visa classification is relevant for individuals seeking entry to the United States to engage in temporary business activities. It permits business activities like conventions, conferences, consultations, and other legitimate commercial and professional activities. This category does not allow individuals to engage in local employment for hire within the United States.",
                },
                {
                  title: "B-2 Visa",
                  description:
                    "This visa classification is relevant for individuals planning to visit the United States for pleasure. It is appropriate for tourism, social visits, certain medical treatments, and participation in amateur sports, music and related events",
                },
                {
                  title: "Visa Waiver Program",
                  description:
                    "The Visa Waiver Program (VWP) is available to citizens of a specified list of countries. The VWP facilitates routine travel for up to 90 days for business or pleasure by eliminating the need to apply for a visa at a U.S. consulate prior to entry. However, it contains strict prohibitions on obtaining extensions or changes of status within the United States.",
                },
              ],
            },
            {
              title: "Work Visas",
              types: [
                {
                  title: "H1B Visa",
                  description:
                    "The H1B visa permits a foreign national to work in the United States temporarily for offers of employment that are in a specialty occupation. H1B visas are numerically limited, with a total of 85,000 visas available each fiscal year (20,000 of these visas are restricted to individuals who have received master’s degrees or higher from U.S colleges or universities). ",
                },
                {
                  title: "L1 Visa",
                  description:
                    "The L-1 visa is appropriate for the transfer of specified types of employees within multinational companies. This visa permits a U.S. company to temporarily transfer certain employees from an affiliated foreign company. The employee must be offered employment in either an executive / managerial position or in a specialized knowledge position to qualify for this classification.",
                },
                {
                  title: "E-3 Visa",
                  description:
                    'The E-3 visa permits U.S. employers to hire Australian nationals as "specialty workers" in certain professional occupations and employ them in the United States on a temporary basis. Initial E-3 status may be issued for a maximum of two years, but E-3 status may be renewed indefinitely, in two-year increments.',
                },
              ],
            },
            {
              title: "Other Work Visas",
              types: [
                {
                  title: "E-1 and E-2 visas",
                  description:
                    "The E-1 (treaty trader) and E-2 visas (treaty investor) allow nationals of countries having commercial treaties with the United States to engage in trade or business investment activities. ",
                },
                {
                  title: "H2B Visa",
                  description:
                    "The H2B visa allows for entry of certain short-term, temporary workers",
                },
                {
                  title: "O Visa",
                  description:
                    "The O visa is for persons of extraordinary ability in the arts, sciences, education, business, or athletics.",
                },
                {
                  title: "P-1, P-2, and P-3 visas",
                  description:
                    "The P-1, P-2, and P-3 visas are for certain types of performers and athletes.",
                },
                {
                  title: "Q visa",
                  description:
                    "The Q visa allows entry to persons participating in an international cultural exchange program designated by the USCIS, intended to provide foreign citizens with practical training in the United States, while permitting them to introduce the culture, history, and traditions of their respective home countries to the United States.",
                },
                {
                  title: "R visa",
                  description: "The R visa is for religious workers.",
                },
                {
                  title: "TN Visa",
                  description:
                    "The TN category is a potential option for citizens of Canada and Mexico who wish to work in the United States. It is for professionals, who are offered employment in a specific occupation listed in the North American Free Trade Agreement (NAFTA)",
                },
                {
                  title: "H-3 Visa",
                  description:
                    "The H-3 category allows noncitizens coming temporarily to the United States as either a: Trainee to receive training in any field of endeavor, other than graduate medical education or training, that is not available in the noncitizen’s home country or Special Education Exchange Visitor to participate in a special education exchange visitor training program that provides for practical training and experience in the education of children with physical, mental, or emotional disabilities.",
                },
                {
                  title:
                    "International Entrepreneur Rule permits the use of parole for “entrepreneurs of start-up entities whose entry into the United States would provide a significant public benefit through the substantial and demonstrated potential for rapid business growth and job creation.",
                },
              ],
            },
            {
              title: "Permanent Resident /Green Card (Employment Based)",
              types: [
                {
                  title: "EB-1",
                  description:
                    "The employment-based, first preference (EB1), or priority workers, category is reserved for persons of extraordinary ability (EB1(a)), outstanding professors and researchers (EB1(b)), and multinational executives and managers (EB1(c)).",
                },
                {
                  title: "EB 2",
                  description:
                    "This employment-based, second preference visa is available if you are a member of the professions holding an advanced degree or its equivalent, or a foreign national who has exceptional ability. ",
                },
                {
                  title: "EB2 NIW",
                  description:
                    "The EB2 national interest waiver (NIW) category involves members of the professions who hold advanced degrees or persons of exceptional ability. Although the EB2 category normally requires an employer and a labor certification, the Attorney General may waive this requirement if the work by the applicant is in the national interest.",
                },
                {
                  title: "EB 3",
                  description:
                    "You may be eligible for this immigrant visa preference category if you are a skilled worker, professional, or other workers. The “other workers” subcategory is for persons performing unskilled labor requiring less than 2 years training, education, or experience, not of a temporary or seasonal nature.",
                },
                {
                  title: "EB4",
                  description:
                    "The EB4 category is for a broad group of special immigrants that most commonly includes religious workers, broadcasters, Iraqi / Afghan translators, Iraqis who have assisted the United States, international organization employees,  certain physicians, armed forces members, Panama Canal zone employees, retired NATO-6 employees, as well as spouses and children of deceased NATO-6 employees.",
                },
                {
                  title: "EB 5",
                  description:
                    "The EB5 category is reserved for foreign nationals who invest one million dollars in a new commercial enterprise that employs ten or more U.S. citizens or authorized immigrant workers. (Investment of $500,000 is required if it is in a rural area or in an area of high unemployment.) EB5 investors obtain lawful permanent resident (LPR) status on a conditional basis valid for two years. After the expiration of the initial two-year period, EB5 investors have to file a separate application with the USCIS to remove conditions on their LPR status.",
                },
              ],
            },
            {
              title: "Permanent Resident /Green Card (Diversity Visa/Lottery)",
              types: [
                {
                  title:
                    "The U.S. Department of State (DOS) conducts an annual visa lottery program. This program, allocates 50,000 immigrant visa numbers each year. These visa numbers are granted to individuals who are chargeable to countries with low rates of emigration to the U.S. ",
                },
              ],
            },
          ],
        },
        {
          type: "business",
          title: "Business Law",
          about:
            "We provide comprehensive General Counsel services to corporations",
          bannerImage: "business-visa-banner.jpg",
          promise:
            "Busayo Fasidi, started her private law practice in November 2008. Prior to that she was opportune to serve as a student attorney at the University of Houston Immigration Law Clinic, where she was mentored by some of the best in the field of U.S. Immigration Law.<br /><br /> She is admitted to practice law in the States of New York and Texas. She is also admitted to the U.S District Courts of the Northern and Southern Districts of Texas.",
          promiseImage: "imigration-promise.png",
          stats: [
            {
              title: "250+",
              value: "Clients",
            },
            {
              title: "250+",
              value: "Clients",
            },
            {
              title: "250+",
              value: "Clients",
            },
            {
              title: "250+",
              value: "Clients",
            },
          ],
          services: [
            // "Legal Advice and Counsel",
            // "Contract Solutions",
            // "Corporate governance and compliance",
            {
              title: "Legal Advice and Counsel",
              types: [
                {
                  title:
                    "A complete range of legal services to ownership and management teams in general corporate matters.",
                },
                {
                  title: "Serve as a trusted Legal Advisor and problem solver",
                },
                {
                  title: "Identify and address legal Issues upfront",
                },
              ],
            },
            {
              title: "Contract Solutions",
              types: [
                {
                  title:
                    "Helps companies establish a contracting process and implement a contract management process. Drafting, reviewing revising and negotiating general commercial and corporate contracts and transactions.",
                },
              ],
            },
            {
              title: "Corporate governance and compliance",
              types: [
                {
                  title: "Compliance programs and trainings",
                },
                {
                  title: "Board of Director/Shareholder meeting records",
                },
                {
                  title: "Conflicts of interests",
                },
              ],
            },
          ],
        },
      ],
    };
  },
};
</script>

<style scoped>
.header {
  position: relative;
}

h1 {
  color: #fff;
  position: absolute;
  top: 0;
  left: 50px;
  font-weight: bold;
  font-family: "Playfair Display";
  font-size: 48px;
  margin-top: 10%;
}

.content,
.item {
  padding-left: 5rem;
  margin-top: 30px;
}

h2 {
  color: #292929;
}

p {
  color: #757575;
  font-size: 18px;
  /* width: 80%; */
}

.header > img {
  height: 350px;
  object-fit: cover;
  width: 100%;
  /* object-position: top; */
}

.promise {
  display: flex;
  justify-content: space-between;
}

.promise-img {
  width: 300px;
  flex: 0.3;
  display: block;
}

.promise > div {
  width: 70%;
}

.stats {
  display: flex;
  justify-content: space-between;
  background-color: var(--primary-color);
  padding: 2rem 8rem;
  margin-top: 30px;
}

.stat > * {
  color: #fff;
}

.stat > h2 {
  font-weight: bold;
  font-size: 40px;
}

.mbl {
  display: none;
}

.not_mbl {
  display: block;
}

.btn-light {
  text-align: left;
}

ol {
  list-style: none;
  padding-left: 0;
  margin-left: 0;
}

@media only screen and (max-width: 600px) {
  h1 {
    left: 10px;
    font-size: 30px;
  }

  .header > img {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }

  h2 {
    font-size: 24px;
  }

  p {
    font-size: 16px;
    width: 100%;
  }

  .promise {
    flex-wrap: wrap;
  }

  .promise > div {
    width: 100%;
  }

  .content,
  .item {
    padding: 1rem;
  }

  .mbl {
    display: block;
  }

  .not_mbl {
    display: none;
  }

  .stats {
    /* flex-wrap: wrap; */
    padding: 1rem;
  }

  .stat > h2 {
    font-size: 26px;
  }
}
</style>