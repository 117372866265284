<template>
  <section class="footer-alt">
    <img src="../assets/images/fslawpc-logo-3.svg" alt="FSLaw PC" class="mt-2" />
    <h1>Texas Business and Immigration Law Firm serving clients worldwide.</h1>
    <footer-comp></footer-comp>
  </section>
</template>

<script>
import FooterComp from "./Footer.vue";
export default {
  components: { FooterComp },
  name: "FooterAlt",
};
</script>

<style scoped>
.footer-alt {
  background-image: url("../assets/images/footer-alt-bg.png");
  box-shadow: inset 0 0 0 2000px rgba(196, 196, 196, 0.2);
}

h1 {
  color: #fff;
  width: 60%;
  margin: 2rem 0;
  font-weight: bold;
  font-family: "Playfair Display";
}

@media only screen and (max-width: 600px) {
  h1 {
    font-size: 26px;
    width: 100%;
  }
}
</style>