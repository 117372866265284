<template>
  <div>
    <nav-bar></nav-bar>
    <div class="mx-5 mt-5">
      <b-card no-body>
        <b-tabs pills card justified>
          <b-tab title="Appointments" active
            ><b-card-text> <appointments></appointments> </b-card-text
          ></b-tab>
          <b-tab title="Website Query"
            ><b-card-text>
              <website-queries></website-queries> </b-card-text
          ></b-tab>
        </b-tabs>
      </b-card>
    </div>
  </div>
</template>

<script>
import Appointments from "./Appointments.vue";
import NavBar from "./NavBar.vue";
import WebsiteQueries from "./WebsiteQueries.vue";
export default {
  components: { NavBar, Appointments, WebsiteQueries },
};
</script>

<style scoped>
>>> .nav-pills .nav-link.active {
  background-color: #640101 !important;
  color: #fff;
}
</style>