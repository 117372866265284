<template>
  <section>
    <div class="heading">ALSO READ</div>
    <div class="news-list">
      <div class="news-item" v-for="(item, index) in items" :key="index">
        <img
          :src="require(`../../assets/images/${item.cover_image}`)"
          :alt="item.title"
        />
        <div>
          <h4 class="title">{{ item.title }}</h4>
          <p class="description">{{ item.description }}</p>
          <div class="info">
            <span class="date">{{ item.date }}</span>
            <span class="tag">{{ item.tag }}</span>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "AlsoRead",
  data() {
    return {
      items: [
        {
          cover_image: "also-read-header.png",
          title: "Saving the Afghan Interpreters: Our Moral Duty",
          description:
            "Despite assurances from the Biden administration, the evacuation of Afghan interpreters is tied up in bureaucratic squabbling",
          date: "22 Jul 2021",
          tag: "Inclusion",
        },
        {
          cover_image: "also-read-header.png",
          title: "Saving the Afghan Interpreters: Our Moral Duty",
          description:
            "Despite assurances from the Biden administration, the evacuation of Afghan interpreters is tied up in bureaucratic squabbling",
          date: "22 Jul 2021",
          tag: "Inclusion",
        },
        {
          cover_image: "also-read-header.png",
          title: "Saving the Afghan Interpreters: Our Moral Duty",
          description:
            "Despite assurances from the Biden administration, the evacuation of Afghan interpreters is tied up in bureaucratic squabbling",
          date: "22 Jul 2021",
          tag: "Inclusion",
        },
        {
          cover_image: "also-read-header.png",
          title: "Saving the Afghan Interpreters: Our Moral Duty",
          description:
            "Despite assurances from the Biden administration, the evacuation of Afghan interpreters is tied up in bureaucratic squabbling",
          date: "22 Jul 2021",
          tag: "Inclusion",
        },
      ],
    };
  },
};
</script>

<style scoped>
.heading {
  color: #949494;
  letter-spacing: 10px;
  font-size: 18px;
}

.news-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px 0;
  flex-wrap: wrap;
}

.news-item {
  width: 47%;
  margin-bottom: 30px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}

.news-item > * {
  flex: 0.5;
}

.news-item > div {
  margin-left: 10px;
}

img {
  width: 100%;
}

.info {
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  width: 46%;
}

.date {
  font-size: 12px;
}

.tag {
  font-size: 12px;
  color: #640101;
}

.title {
  color: #292929;
  font-size: 16px;
}

.description {
  color: #757575;
  font-size: 14px;
}

@media only screen and (max-width: 600px) {
  .news-list {
    flex-wrap: wrap;
  }

  .news-item {
    width: 100%;
    margin-bottom: 20px;
  }

  .news-item {
    flex-wrap: wrap;
  }

  .news-item > * {
    flex: 1;
    width: 100%;
  }

  .news-item > div {
    margin-top: 10px;
    margin-left: 0;
  }

  .info {
    width: 100%;
  }

  img {
    width: 200px;
  }
}
</style>