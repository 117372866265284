<template>
  <div>
    <div class="about-us">
      <nav-bar class="nav-bar"></nav-bar>
      <div class="content">
        <h1 class="animate__animated animate__fadeInDown">
          Over 40 years <br />of combined Legal Experience
        </h1>
        <div class="desc">
          Our mission is to provide excellent representation while implementing
          customized legal services for our clients. Our core values are
          Excellence, Reliability & Collaboration.
        </div>
      </div>
      <h3>Our Core Values</h3>
      <our-values class="mt-3 item"></our-values>
      <!-- <div class="mx-5 mt-5 desc-2">
        With a combined experienced of 40 years, our attorneys have shared
        aspirations, common values and a strong work ethic which contributes to
        deliver excellent client service across the globe. Collaboration leads
        to us explore novel strategies to better serve our clients and deliver
        high quality, cost-effective, personal service. Our firm’s success and
        strength is based on a culture based on personal relationships, and a
        shared passion for the best interests of our clients We consider the
        business of our clients as our business and provide a personalized,
        excellent and reliable service. Our commitment to excellence combined
        with reliability and collaboration leads to deliver the best results for
        our clients.
      </div> -->
      <h3 class="attorney-heading">Partners</h3>
      <attorneys class="mt-3 item"></attorneys>
      <!-- <h3 style="color: #640101; padding-left: 0" class="text-center">
        Testimonials
      </h3> -->
      <!-- <testimonials class="mt-5"></testimonials> -->
      <footer-alt class="item"></footer-alt>
    </div>
  </div>
</template>

<script>
import Attorneys from "../components/Attorneys.vue";
import FooterAlt from "../components/FooterAlt.vue";
import NavBar from "../components/NavBar.vue";
import OurValues from "../components/OurValues.vue";
// import Testimonials from "../components/Testimonials.vue";
export default {
  components: {
    NavBar,
    OurValues,
    Attorneys,
    FooterAlt,
    // Testimonials
  },
  name: "AboutUs",
};
</script>

<style scoped>
.about-us {
  background-image: url("../assets/images/about-us-bg.png");
  height: 100vh;
  width: 100vw;
  position: relative;
  box-shadow: inset 0 0 0 2000px rgba(196, 196, 196, 0.6);
}

.nav-bar,
.content {
  padding: 0 5rem;
}

h3,
.item {
  padding-left: 5rem;
}

h1 {
  font-size: 60px;
  font-weight: bold;
  color: #fff;
  line-height: 60px;
  font-family: "Playfair Display";
}

.desc {
  color: #000 !important;
  /* font-weight: bold; */
}

.desc-2 {
  color: #000 !important;
  font-size: 18px;
  line-height: 30px;
  margin-top: 2rem;
}

.content {
  width: 72%;
  margin-top: 100px;
}

.content > div {
  font-size: 18px;
  color: #fff;
  margin: 2rem 0;
}

h3 {
  color: #fff;
  margin-top: 100px;
  font-weight: bold;
}

.attorney-heading {
  color: #640101;
}

@media only screen and (max-width: 600px) {
  .about-us {
    background-position: center;
  }

  .nav-bar {
    padding: 0;
  }

  .content {
    padding: 1rem;
    width: 100%;
  }

  h1 {
    font-size: 36px;
    line-height: 40px;
  }

  .content > div {
    font-size: 14px;
  }

  h3,
  .item {
    padding-left: 1rem;
  }

  .item {
    margin-top: 2rem;
  }
}
</style>