<template>
  <section>
    <img
      src="../../assets/images/fslawpc-logo-2.svg"
      alt="FS Law Logo"
      class="logo mx-auto d-block"
    />
    <b-btn variant="primary" class="mx-auto d-block mt-5" @click="login()">
      <b-icon icon="google" class="mr-2" />
      Login with google</b-btn
    >
  </section>
</template>

<script>
import {
  auth,
  doc,
  GoogleAuthProvider,
  setDoc,
  signInWithPopup,
  usersRef,
} from "../../config/firebase";

export default {
  methods: {
    async login() {
      try {
        const provider = new GoogleAuthProvider();
        const response = await signInWithPopup(auth, provider);
        console.log(response.user);
        const user = {
          uid: response.user.uid,
          name: response.user.displayName,
          email: response.user.email,
          photo: response.user.photoURL,
        };
        await setDoc(doc(usersRef, response.user.uid), user, { merge: true });
        this.$router.push("/admin/dashboard");
      } catch (error) {
        console.log(error);
        this.$toast.error(error.message, {
          position: "top",
        });
      }
    },
  },
};
</script>

<style scoped>
</style> 