<template>
  <section>
    <nav-bar-white></nav-bar-white>
    <div class="c4">
      <p class="c9">
        <span class="c8">&nbsp;</span><span class="c10">TERMS OF USE</span>
      </p>
      <p class="c6">
        <span class="c3"
          >FS Law PC website is provided for informational purposes only, and
          should not be construed &nbsp;as specific legal advice on any subject
          matter.. Use and access to this&nbsp;website&nbsp;does not create an
          attorney-client relationship, nor will any information you submit to
          us via this site or by e-mail be considered an attorney-client
          communication or otherwise be treated as confidential or privileged in
          the absence of a pre-existing agreement with us to the contrary.</span
        >
      </p>
      <p class="c6">
        <span class="c3"
          >The content of this site may be considered advertising for legal
          services under the laws and rules of professional conduct of the
          jurisdictions in which we practice.
        </span>
      </p>
      <p class="c6">
        <span class="c3"
          >Unless otherwise indicated, &nbsp;attorneys &nbsp;listed on this
          website are not certified by the Texas Board of Legal
          Specialization.&nbsp;</span
        >
      </p>
      <p class="c2"><span class="c5">DISCLAIMER OF WARRANTIES</span></p>
      <p class="c2"><span class="c1">&nbsp;</span></p>
      <p class="c7">
        <span class="c1"
          >All products, services and content on this site are provided on an
          &ldquo;As Is&rsquo; &nbsp;basis without warranty of any kind including
          but not limited to the implied warranties of merchantability, fitness
          for a particular use, and the warranty of non-infringement. Without
          limiting the foregoing, we make no warranty that the services and
          content provided on or through this site will meet your requirements
          or that the use will be uninterrupted, secure or error free or will
          meet your expectations or be free from mistakes, errors or
          defects.</span
        >
      </p>
      <p class="c2"><span class="c1">&nbsp;</span></p>
      <p class="c2"><span class="c5">LIMITATION OF LIABILITY</span></p>
      <p class="c2"><span class="c5">&nbsp;</span></p>
      <p class="c7">
        <span class="c1"
          >You agree that neither FS Law PC &nbsp;or its &nbsp;affiliates will
          be liable to you or any third party for any special, punitive,
          incidental, indirect or consequential damages of any kind, or for any
          claims, losses or damages whatsoever, however caused or under any
          theory of liability, arising out of or in connection with the use of
          this site or any website referenced or linked to from this site,
          and/or the use of or reliance upon any content or information provided
          on this site.</span
        >
      </p>
      <p class="c2"><span class="c1">&nbsp;</span></p>
      <p class="c2"><span class="c5">INDEMNIFICATION</span></p>
      <p class="c2"><span class="c5">&nbsp;</span></p>
      <p class="c7">
        <span class="c1"
          >To the extent permitted by law, You agree to defend, indemnify, and
          hold FS Law PC and &nbsp;its Affiliates harmless from all liabilities,
          claims, and expenses, including attorney fees, that arise from your
          use or misuse of this site.
        </span>
      </p>
      <p class="c0"><span class="c5"></span></p>
      <p class="c2"><span class="c5">GOVERNING LAW</span></p>
      <p class="c2"><span class="c1">&nbsp;</span></p>
      <p class="c2">
        <span class="c1"
          >This Agreement shall be construed and in accordance with the laws of
          the state of Texas.
        </span>
      </p>
      <p class="c0"><span class="c1"></span></p>
      <p class="c2"><span class="c5">ENTIRE AGREEMENT</span></p>
      <p class="c2"><span class="c5">&nbsp;</span></p>
      <p class="c2">
        <span class="c1"
          >These terms constitute the entire agreement between you FS Law PC
          related to this website &nbsp;and the content, products, and services
          that may be provided here. These Terms supersede and replace any and
          all prior oral or written agreements between you and FS Law PC.</span
        >
      </p>
      <p class="c2"><span class="c1">&nbsp;</span></p>
    </div>
  </section>
</template>

<script>
import NavBarWhite from "../components/NavBarWhite.vue";
export default {
  components: { NavBarWhite },
};
</script>

<style scoped>
@import url("https://themes.googleusercontent.com/fonts/css?kit=fpjTOVmNbO4Lz34iLyptLUXza5VhXqVC6o75Eld_V98");

section {
  padding: 0 5rem;
}

ol {
  margin: 0;
  padding: 0;
}

table td,
table th {
  padding: 0;
}

.c10 {
  color: #202124;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 12pt;
  font-family: "Times New Roman";
  font-style: normal;
}

.c5 {
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 12pt;
  font-family: "Times New Roman";
  font-style: normal;
}

.c0 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 25px;
  orphans: 2;
  widows: 2;
  text-align: left;
  height: 11pt;
}

.c3 {
  color: #202124;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 12pt;
  font-family: "Times New Roman";
  font-style: normal;
}

.c1 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 12pt;
  font-family: "Times New Roman";
  font-style: normal;
}

.c9 {
  padding-top: 0pt;
  padding-bottom: 9pt;
  line-height: 25px;
  orphans: 2;
  widows: 2;
  text-align: center;
}

.c2 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 25px;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.c7 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 25px;
  orphans: 2;
  widows: 2;
  text-align: justify;
}

.c6 {
  padding-top: 0pt;
  padding-bottom: 9pt;
  line-height: 25px;
  orphans: 2;
  widows: 2;
  text-align: justify;
}

.c8 {
  font-size: 15pt;
  font-family: "inherit";
  color: #202124;
  font-weight: 700;
}

.c4 {
  background-color: #ffffff;
  width: 100%;
  padding: 72pt 72pt 72pt 72pt;
}

.title {
  padding-top: 24pt;
  color: #000000;
  font-weight: 700;
  font-size: 36pt;
  padding-bottom: 6pt;
  font-family: "Calibri";
  line-height: 1.0791666666666666;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.subtitle {
  padding-top: 18pt;
  color: #666666;
  font-size: 24pt;
  padding-bottom: 4pt;
  font-family: "Georgia";
  line-height: 1.0791666666666666;
  page-break-after: avoid;
  font-style: italic;
  orphans: 2;
  widows: 2;
  text-align: left;
}

li {
  color: #000000;
  font-size: 11pt;
  font-family: "Calibri";
}

p {
  margin: 0;
  color: #000000;
  font-size: 11pt;
  font-family: "Calibri";
}

h1 {
  padding-top: 24pt;
  color: #000000;
  font-weight: 700;
  font-size: 24pt;
  padding-bottom: 6pt;
  font-family: "Calibri";
  line-height: 1.0791666666666666;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

h2 {
  padding-top: 18pt;
  color: #000000;
  font-weight: 700;
  font-size: 18pt;
  padding-bottom: 4pt;
  font-family: "Calibri";
  line-height: 1.0791666666666666;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

h3 {
  padding-top: 0pt;
  color: #000000;
  font-weight: 700;
  font-size: 13.5pt;
  padding-bottom: 8pt;
  font-family: "Times New Roman";
  line-height: 25px;
  orphans: 2;
  widows: 2;
  text-align: left;
}

h4 {
  padding-top: 12pt;
  color: #000000;
  font-weight: 700;
  font-size: 12pt;
  padding-bottom: 2pt;
  font-family: "Calibri";
  line-height: 1.0791666666666666;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

h5 {
  padding-top: 11pt;
  color: #000000;
  font-weight: 700;
  font-size: 11pt;
  padding-bottom: 2pt;
  font-family: "Calibri";
  line-height: 1.0791666666666666;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

h6 {
  padding-top: 10pt;
  color: #000000;
  font-weight: 700;
  font-size: 10pt;
  padding-bottom: 2pt;
  font-family: "Calibri";
  line-height: 1.0791666666666666;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
</style>