<template>
  <div>
    <nav-bar-white class="nav-bar"></nav-bar-white>
    <news-header :newsHeader="headerImage"></news-header>
    <div class="content">
      <div class="heading">LATEST NEWS</div>
      <div class="news-list">
        <div
          class="news-item"
          v-for="(item, index) in news"
          :key="index"
          @click="goto()"
        >
          <img
            :src="require(`../assets/images/${item.cover_image}`)"
            :alt="item.title"
          />
          <div class="info">
            <span class="date">{{ item.date }}</span>
            <span class="tag">{{ item.tag }}</span>
          </div>
          <h4 class="title">{{ item.title }}</h4>
          <p class="description">{{ item.description }}</p>
        </div>
      </div>
      <also-read class="mt-5"></also-read>
    </div>
    <footer-alt class="item"></footer-alt>
  </div>
</template>

<script>
import FooterAlt from "../components/FooterAlt.vue";
import NavBarWhite from "../components/NavBarWhite.vue";
import AlsoRead from "../components/News/AlsoRead.vue";
import NewsHeader from "../components/News/Header.vue";
export default {
  components: { NewsHeader, NavBarWhite, FooterAlt, AlsoRead },
  name: "NewsAndBlogs",
  data() {
    return {
      news: [
        {
          cover_image: "news_cover.png",
          title: "Saving the Afghan Interpreters: Our Moral Duty",
          description:
            "Despite assurances from the Biden administration, the evacuation of Afghan interpreters is tied up in bureaucratic squabbling",
          date: "22 Jul 2021",
          tag: "Inclusion",
        },
        {
          cover_image: "news_cover.png",
          title: "Saving the Afghan Interpreters: Our Moral Duty",
          description:
            "Despite assurances from the Biden administration, the evacuation of Afghan interpreters is tied up in bureaucratic squabbling",
          date: "22 Jul 2021",
          tag: "Inclusion",
        },
        {
          cover_image: "news_cover.png",
          title: "Saving the Afghan Interpreters: Our Moral Duty",
          description:
            "Despite assurances from the Biden administration, the evacuation of Afghan interpreters is tied up in bureaucratic squabbling",
          date: "22 Jul 2021",
          tag: "Inclusion",
        },
      ],
      headerImage: "news-header.png",
    };
  },
  methods: {
    goto() {
      this.$router.push("/read-news");
    },
  },
};
</script>

<style scoped>
.nav-bar,
.content {
  padding: 0 5rem;
}

.content {
  margin-top: 100px;
}

.heading {
  color: #949494;
  letter-spacing: 10px;
  font-size: 18px;
}

.news-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.news-item {
  width: 30%;
  margin-bottom: 20px;
  cursor: pointer;
}

img {
  width: 100%;
}

.info {
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  width: 40%;
}

.date {
  font-size: 12px;
}

.tag {
  font-size: 12px;
  color: #640101;
}

.title {
  color: #292929;
  font-size: 18px;
}

.description {
  color: #757575;
  font-size: 14px;
}

.item {
  padding-left: 5rem;
}

@media only screen and (max-width: 600px) {
  .news-list {
    flex-wrap: wrap;
  }

  .news-item {
    width: 100%;
  }

  .nav-bar {
    padding: 0;
  }

  .content {
    padding: 1rem;
  }

  .item {
    padding-left: 1rem;
  }
}
</style>