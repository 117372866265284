<template>
  <section>
    <img
      :src="require(`../../assets/images/${newsHeader}`)"
      alt="News Header"
    />
    <h1>News</h1>
  </section>
</template>

<script>
export default {
  name: "NewsHeader",
  props: ["newsHeader"],
};
</script>

<style scoped>
section {
  position: relative;
}

h1 {
  color: #fff;
  position: absolute;
  top: 0;
  left: 50px;
  font-weight: bold;
  font-family: "Playfair Display";
  font-size: 48px;
  margin-top: 10%;
}

@media only screen and (max-width: 600px) {
  img {
    width: 100%;
    height: 250px;
    object-fit: cover;
  }
}
</style>