<template>
  <div class="practice-areas">
    <div class="overlay"></div>
    <nav-bar class="nv"></nav-bar>
    <div class="content">
      <h1>Our Expertise</h1>
      <div>
        Our mission is to provide excellent representation while implementing
        customized legal services for our clients. Our core values are
        Excellence, Reliability & Collaboration.
      </div>
    </div>
    <div class="types-row">
      <div class="types-col animate__animated animate__fadeInUp">
        <h3 class="types-title">Immigration Law</h3>
        <div class="types-content my-4">
          We provide comprehensive business immigration services to individuals
          and corporations from all over the world
        </div>
        <b-button
          variant="primary"
          class="my-4"
          @click="goto('/services/imigration')"
        >
          Read more<b-icon
            icon="arrow-right"
            aria-hidden="true"
            class="ml-3"
          ></b-icon>
        </b-button>
      </div>
      <div class="types-col animate__animated animate__fadeInUp">
        <h3 class="types-title">Business Law</h3>
        <div class="types-content my-4">
          We provide comprehensive outside General Counsel services to
          corporations
        </div>
        <b-button
          variant="primary"
          class="my-4"
          @click="goto('/services/business')"
        >
          Read more<b-icon
            icon="arrow-right"
            aria-hidden="true"
            class="ml-3"
          ></b-icon>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "../components/NavBarWhite.vue";
export default {
  components: { NavBar },
  name: "PracticeAreas",
  methods: {
    goto(path) {
      this.$router.push(path);
    },
  },
};
</script>

<style scoped>
.practice-areas {
  background-image: url("../assets/images/practice-areas-bg.png");
  height: 100vh;
  width: 100%;
  position: relative;
  padding: 0 5rem;
}

h1 {
  font-size: 60px;
  font-weight: bold;
  color: #fff;
  line-height: 60px;
  font-family: "Playfair Display";
}

.content {
  width: 80%;
  padding: 1rem;
  margin-top: 100px;
}

.content > div {
  font-size: 18px;
  color: #fff;
  margin: 2rem 0;
}

.types-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 0;
}

.types-col {
  width: 50%;
  height: 350px;
  color: #fff;
  padding: 3rem;
  cursor: pointer;
}
.types-col:nth-child(1) {
  background-color: #640101;
}

.types-col:nth-child(2) {
  background: rgba(0, 0, 0, 0.81);
}

.btn-primary {
  background-color: transparent !important;
  color: #fff !important;
  border: 1px solid #fff;
  border-radius: 0;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 80px;
  background-color: #fff;
  top: 0;
  opacity: 0.9;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

@media only screen and (max-width: 600px) {
  .practice-areas {
    padding: 0;
  }

  .types-row {
    flex-wrap: wrap;
    position: relative;
  }
  .types-col {
    width: 100%;
  }

  h1 {
    font-size: 40px;
  }

  .content {
    width: 100%;
  }

  .content > div {
    font-size: 14px;
    line-height: 30px;
  }

  .types-title {
    font-size: 20px;
  }

  .types-content {
    font-size: 14px;
    line-height: 30px;
  }
}
</style>