<template>
  <div class="mx-5">
    <b-navbar toggleable="lg">
      <b-navbar-brand to="/">
        <img src="../../assets/images/fslawpc-logo-3.svg" alt="FS Law PC" />
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-nav-item>
            <b-button
              variant="primary"
              class="animate__animated animate__tada"
              @click="logout()"
              >Logout</b-button
            >
          </b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import { auth } from "../../config/firebase";
export default {
  components: {},
  name: "NavBar",
  data() {
    return {};
  },
  methods: {
    async logout() {
      try {
        await auth.signOut();
        this.$router.push("/");
      } catch (error) {
        console.log(error);
        this.$toast.error(error.message, {
          position: "top",
        });
      }
    },
  },
};
</script>

<style scoped>
.nav-item {
  margin-right: 1rem;
}

>>> .nav-link:hover {
  color: #640101 !important;
}

.nav-link {
}

.btn-primary {
  background-color: #640101 !important;
  border: 1px solid var(--primary-color);
}

.btn:hover {
  background-color: var(--primary-color) !important;
}

@media only screen and (max-width: 600px) {
  .navbar-collapse {
    text-align: center;
  }
}
</style>